<template>
  <v-app
    class="my-app"
    :style="{
      background: `${$vuetify.theme.themes[theme].background} !important`,
    }"
  >
    <div class="menu-btnz" v-show="!drawer">
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon color="accent">mdi-menu</v-icon>
      </v-btn>
    </div>
    <v-navigation-drawer v-model="drawer" app>
      <div style="padding: 20px">
        <v-img
          :src="logo"
          style="
            display: inline-block;
            vertical-align: middle;
            margin-top: -12px;
            margin-right: 10px;
          "
          heigth="40px"
          width="40px"
        />
        <h1
          style="
            color: #6441a5;
            font-size: 30px;
            display: inline-block;
            cursor: pointer;
          "
          @click="$route.name !== 'Home' ? $router.push('/') : null"
        >
          Bity Alerts
        </h1>

        <div
          style="padding-top: 20px; font-weight: bold"
          v-if="$route.name !== 'Alerts' && routes.length > 0"
        >
          Streamer Alerts
        </div>
        <hr v-if="$route.name !== 'Alerts'" />
        <v-list v-if="$route.name !== 'Alerts'" flat dense nav class="py-1">
          <v-list-item-group mandatory v-if="routes.length > 0">
            <v-list-item
              v-for="item in routes"
              :key="item.title"
              dense
              router
              :to="item.route"
              class="nav"
            >
              <!-- <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon> -->
              <v-list-item-avatar
                tile
                size="30"
                color="grey"
                v-if="item.streamer.profileSrc"
              >
                <img :src="item.streamer.profileSrc" class="rounded-circle" />
              </v-list-item-avatar>
              <v-list-item-content>
                {{ item.title }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div class="spade-char" v-if="page.icon && page.icon.src">
          <img :src="`https://firebasestorage.googleapis.com/v0/b/bityalerts.appspot.com/o/${page.icon.src}?alt=media`" />
        </div>
        <div
          v-show="$route.name == 'Alerts' && twitcher"
          style="display: inline-block"
          class="display-name"
        >
          <h2
            style="display: inline-block; font-size: 30px; cursor: pointer"
            @click="goToTwitch(twitcher)"
          >
            {{ page.displayName ? page.displayName : twitcher }}
          </h2>
          <v-icon
            class="twitch"
            @click="goToTwitch(twitcher)"
            size="26px"
            color="white"
            style="
              vertical-align: middle;
              margin-bottom: 13px;
              padding-left: 4px;
              display: inline-block;
            "
          >
            mdi-twitch
          </v-icon>
        </div>
        <h3
          v-show="$route.name == 'Alerts'"
          :style="`margin-top: 10px; color: ${$vuetify.theme.themes[theme].text}`"
        >
          {{page.text || 'Bits will trigger an alert on stream instantly via donation or Twitchs Bits!'}}
        </h3>
        <div v-show="$route.name == 'Alerts'" style="margin-top: 10px">
          <v-text-field
            @keyup="updateSearch"
            v-model="search"
            label="Search for Alert"
          ></v-text-field>
          <label>Bity Range</label>
          <v-range-slider
            :color="$vuetify.theme.themes[theme].secondary"
            v-model="bitRange"
            :track-color="$vuetify.theme.themes[theme].primary"
            :max="bitMax"
            :min="bitMin"
            hide-details
            class="align-center"
          >
            <template v-slot:prepend>
              <v-text-field
                :value="bitRange[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(bitRange, 0, $event)"
              ></v-text-field>
            </template>
            <template v-slot:append>
              <v-text-field
                :value="bitRange[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(bitRange, 1, $event)"
              ></v-text-field>
            </template>
          </v-range-slider>
        </div>

        <hr style="margin-top: 30px" />
        <v-list flat dense nav class="py-1">
          <v-list-item-group mandatory>
            <v-list-item
              v-for="item in nav"
              :key="item.title"
              dense
              router
              :to="item.route"
              class="nav"
            >
              <v-list-item-icon>
                <v-icon
                  :style="{ color: $vuetify.theme.themes[theme].accent }"
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                {{ item.title }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <ba-footer></ba-footer>
    <!-- <v-footer app style="z-index:9999;">
      <div>
        <small
          >Created by:
          <a
            style="color: #6441a5; text-decoration: none"
            href="https://www.twitch.tv/bytesizd"
            target="_blank"
            >Bytesizd</a
          >
          &copy; {{ new Date().getFullYear() }}</small
        >
        <small>
          Join our
          <a
            style="color: #6441a5; text-decoration: none"
            href="https://discord.gg/63Xt6kHzP2"
            target="_blank"
            >Discord!</a
          >
        </small>
        <small style="text-align: right;">v.1.0.2</small>
      </div>
    </v-footer> -->
  </v-app>
</template>

<script>
// import axios from "axios";
import _ from "lodash";
import { mapActions } from "vuex";
import Spades from "./assets/spades.png";
import logo from "./assets/logo.png";
import footer from "./components/Footer"

export default {
  name: "App",

  components: {
    'ba-footer': footer
  },

  data: () => ({
    exists: true,
    twitcher: null,
    search: null,
    bitFilter: 0,
    bitMax: 5001,
    bitMin: 0,
    bitRange: [0, 5001],
    Spades: Spades,
    logo: logo,
    drawer: true,
    dark: false,
    routes: [],
    nav: [
      { icon: "mdi-heart", title: "Favorite Alerts", route: "/favorites" },
      { icon: "mdi-cog", title: "Settings", route: "/settings" },
    ],
  }),

  mounted() {
    let darkmode = localStorage.getItem("darkmode");
    if (darkmode) {
      this.$vuetify.theme.dark = darkmode == "true";
    }
    this.getAlerts();
    this.getStreamers();
    this.rehydrateFav();
  },

  watch: {
    "$vuetify.theme.dark": function (data) {
      localStorage.setItem("darkmode", data);
    },
    "$route.params.twitchid": function () {
      this.getAlerts();
    },
    exists: function (data) {
      if (data == false) {
        this.$router.replace({ name: "NotFound" });
      }
    },
    bitRange: function (range) {
      this.setAlert({
        search: this.search,
        range: { start: range[0], end: range[1] },
      });
    },
  },

  methods: {
    ...mapActions(["setAlert", "rehydrateFav"]),
    updateSearch() {
      this.setAlert({
        search: this.search,
        range: { start: this.bitRange[0], end: this.bitRange[1] },
      });
    },
    goToTwitch(twitcher) {
      window.open(`https://www.twitch.tv/${twitcher}`, "_blank");
    },
    getStreamers() {
      let that = this;
      const streamers = this.$firebase;
      this.routes = [];
      streamers
        .collection("streamers")
        .where("verified", "==", true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((element) => {
            let data = element.data();
            that.routes.push({
              icon: "mdi-alarm-light",
              title: data.page.displayName,
              route: "/" + data.streamer,
              streamer: data
            });
          });
        });
    },
    getAlerts() {
      let alerts = [];
      const streamers = this.$firebase;
      this.twitcher = this.$route.params.twitchid;
      if (!this.twitcher) return;

      streamers
        .collection("alerts")
        .where("streamer", "==", this.twitcher.toLowerCase())
        .orderBy("bits")
        .get()
        .then((querySnapshot) => {
          this.exists = querySnapshot.docs.length > 0;
          querySnapshot.docs.forEach((element) => {
            alerts.push({
              id: element.id,
              ...element.data(),
            });
          });

          this.setAlert({ alerts: alerts });
        });
    },
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    page() {
      if (!this.twitcher) return {}
      let that = this
      let data = _.find(this.routes, function(data) {
        return data.streamer.streamer == that.twitcher.toLowerCase();
      });

      if (!data) return {}

      return data.streamer.page

      // return {
      //   ...data.data.page,
      //   profileSrc: data.data.profileSrc,
      // };

    }
  },
};
</script>
<style>
/* .theme--light.v-application {
  color: #363636 !important;
} */

a {
  color: #57c4e5 !important;
}

.nav {
  transition: all 0.3s;
}
.nav:hover {
  background-color: #ccc;
  transition: all 0.3s;
}

.spade-char {
  margin: 0;
  padding: 0;
  margin-bottom: -10px;
}

.spade-char img {
  margin: 0;
  padding: 0;
  width: 100%;
}

.display-name {
  background-color: #6441A5;
  color: #fff;
  padding: 5px 10px;
  width: 105%;
  border-radius: 6px;
  border-top-left-radius: 0px;
}

.menu-btnz {
  z-index: 9000000;
  position: fixed;
  border-radius: 25px;
  background-color: #efefef;
  right: 0px;
  top: 0px;
  padding-left: 2px;
  padding-top: 2px;
  width: 40px;
  height: 40px;
  margin: 20px;
}
</style>
<style scoped>
/* h1, h2, h3 {
  color: #6441a5 !important;
} */
</style>