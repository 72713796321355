<template>
  <v-footer
    padless
    style="z-index: 9;"
  >
    <v-card
      flat
      tile
      class="background lighten-1 white--text text-center"
      style="width: 100%;"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon.icon"
          class="mx-4 white--text"
          icon
          :href="icon.url"
          target="_blank"
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
          Join Our Discord!
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
          Created by:
          <a
            style="color: #6441a5; text-decoration: none"
            href="https://www.twitch.tv/bytesizd"
            target="_blank"
            >Bytesizd</a
          >
          &copy; {{ new Date().getFullYear() }}
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      icons: [
        {
            url: 'https://discord.gg/63Xt6kHzP2',
            icon: 'mdi-discord'
        }
      ],
    })
  }
</script>