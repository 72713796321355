<template>
  <div class="home">
    <div class="container-grid">
      <tiles
        class="tiles-grid"
        v-for="(alert, i) in getAllFavs"
        :key="alert.description + i"
        :src="alert.src"
        :bits="alert.bits"
        :name="alert.name"
        :data="alert"
        :description="alert.description">
      </tiles>
    </div>
    <h2 style="font-size: 20px; margin-top: 20px; color: white;" v-if="getAllFavs.length <= 0">
      It appears you don't have any favorited Alerts!
    </h2>
    <div style="font-size: 20px; color: white;" v-if="getAllFavs.length <= 0">
      Click the hearts on your favorite Alerts to see them here in the future for quicker access!
      <v-icon
        medium
        dark
      >
      mdi-heart
      </v-icon>
    </div>
  </div>
</template>

<script>
import tiles from "../components/Tiles.vue";
import { mapGetters } from "vuex";
export default {
  name: "Alerts",
  components: {
    tiles,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["getAllFavs"]),
  },
};
</script>
<style scoped>
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 10px;
}

.tiles-grid {
  width: 300px;
}
</style>