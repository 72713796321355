import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                text: '#666',
                color: '#ffffff',
                primary: '#efefef',
                secondary: '#5CBBF6',
                accent: '#E27396',
                background: '#818479',
                navigationbar: '#ffffff'
            },
            dark: {
                text: '#ffffff',
                primary: '#d6d6d6',
                secondary: '#5CBBF6',
                accent: '#E27396',
                background: '#6441A5',
                navigationbar: '#363636'
            },
        },
    },
});
