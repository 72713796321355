import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

Vue.use(Vuex)

// TODO: Segment the alerts per streamer
export default new Vuex.Store({
  state: {
    showingAlerts: [],
    alerts: [],
    favoriteAlerts: [],
    loading: true
  },
  mutations: {
    REHYDRATE_FAV(state) {
      let fav = localStorage.getItem('ba-favorites')
      if (fav) {
        let parsed_fav = JSON.parse(fav)
        state.favoriteAlerts = parsed_fav
      }
    },
    SET_FAV_ALERT(state, data) {
      let alert = _.find(state.favoriteAlerts, (n) => {
        return n.name == data.name && n.bits == data.bits && n.src == data.src && n.description == data.description
      })

      if (!alert || !alert.name) {
        state.favoriteAlerts.push(data)
      }
      localStorage.setItem('ba-favorites', JSON.stringify(state.favoriteAlerts))
    },
    REMOVE_FAV_ALERT(state, data) {
      let alert = _.find(state.favoriteAlerts, (n) => {
        return n.name == data.name && n.bits == data.bits && n.src == data.src && n.description == data.description
      })
      if (alert) {
        state.favoriteAlerts = _.remove(state.favoriteAlerts, (fav) => {
          return fav != alert
        });
      }
      localStorage.setItem('ba-favorites', JSON.stringify(state.favoriteAlerts))
    },
    SET_ALERT(state, data) {
      state.loading = false
      if (data.search == "" || data.search == null) {
        if (data.alerts){
          state.alerts = data.alerts
          state.showingAlerts = data.alerts
          return
        }

        let tempalerts = _.cloneDeep(state.alerts)
        if (data.range && data.range.start !== null && data.range.end !== null) {
          var newerAlerts = _.filter(tempalerts, function(item){
            let test = item.bits >= data.range.start && item.bits <= data.range.end
            return test
          })
          state.showingAlerts = _.cloneDeep(newerAlerts)
          return
        }
        
        state.showingAlerts = tempalerts  
      } else
      if (data.search) {
        let tempalerts = _.cloneDeep(state.alerts)
        var newAlerts = _.filter(tempalerts, function(item){
          if (data.range && data.range.start !== null && data.range.end !== null) {
            let test = item.bits >= data.range.start && item.bits <= data.range.end
            
            let hasName = item.name && item.name.toLowerCase().includes(data.search.toLowerCase())
            let hasDesc = item.description && item.description.toLowerCase().includes(data.search.toLowerCase())
            
            
            return test && ( hasName || hasDesc)
          }
          return item.name.toLowerCase().includes(data.search.toLowerCase()) || item.description.toLowerCase().includes(data.search.toLowerCase())
        })
        state.showingAlerts = _.cloneDeep(newAlerts)
      }
    }
  },
  actions: {
    setAlert ({ commit }, data) {
      commit('SET_ALERT', data)
    },
    setFavAlert ({ commit }, data) {
      commit('SET_FAV_ALERT', data)
    },
    removeFavAlert ({ commit }, data) {
      commit('REMOVE_FAV_ALERT', data)
    },
    rehydrateFav({ commit }) {
      commit('REHYDRATE_FAV')
    }
  },
  getters: {
    alerts: state => {
      return state.showingAlerts
    },
    loading: state => {
      return state.loading
    },
    getAllFavs: state => {
      return state.favoriteAlerts
    },
    getFav: state => {
      return (data) => {
        return _.find(state.favoriteAlerts, (t)=> {
          return t.name == data.name
        })
      }  
    }
  },
  modules: {
  }
})
