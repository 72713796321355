var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",staticStyle:{"overflow":"auto"},attrs:{"max-width":"344"}},[_c('div',{staticStyle:{"height":"200px !important","overflow":"hidden"}},[(_vm.src && _vm.play)?_c('v-img',{attrs:{"height":"200px","id":_vm.refid,"src":("https://firebasestorage.googleapis.com/v0/b/bityalerts.appspot.com/o/" + _vm.src + "?alt=media")},on:{"click":function($event){_vm.play = false}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",style:(("background: " + _vm.randoGrad)),attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,1311044563)},[_c('v-icon',{staticStyle:{"position":"absolute","right":"10px","top":"10px","cursor":"pointer","padding":"10px"},attrs:{"medium":"","dark":""},on:{"click":function($event){_vm.favorite = !_vm.favorite}}},[_vm._v(" "+_vm._s(_vm.favorite ? "mdi-heart" : "mdi-heart-outline")+" ")]),(_vm.newCheck)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"position":"absolute","right":"70px","top":"10px","cursor":"pointer","padding":"10px"},attrs:{"medium":"","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-death-star-variant ")])]}}],null,false,2484455891)},[_c('span',[_vm._v("New!")])]):_vm._e(),(!_vm.isMobile.any())?_c('v-icon',{staticStyle:{"position":"absolute","right":"40px","top":"10px","cursor":"pointer","padding":"10px"},attrs:{"medium":"","dark":""},on:{"click":function($event){_vm.showCopyContent = !_vm.showCopyContent}}},[_vm._v(" mdi-content-copy ")]):_vm._e()],1):_vm._e(),_c('v-img',{directives:[{name:"show",rawName:"v-show",value:(_vm.src && !_vm.play),expression:"src && !play"}],staticClass:"freezeframe",attrs:{"height":"200px","id":_vm.refid,"src":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-icon',{staticStyle:{"position":"absolute","right":"10px","top":"10px","cursor":"pointer","padding":"10px"},attrs:{"medium":"","dark":""},on:{"click":function($event){_vm.favorite = !_vm.favorite}}},[_vm._v(" "+_vm._s(_vm.favorite ? "mdi-heart" : "mdi-heart-outline")+" ")]),(_vm.newCheck)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"position":"absolute","right":"70px","top":"10px","cursor":"pointer","padding":"10px"},attrs:{"medium":"","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-death-star-variant ")])]}}],null,false,2484455891)},[_c('span',[_vm._v("New!")])]):_vm._e(),(!_vm.isMobile.any())?_c('v-icon',{staticStyle:{"position":"absolute","right":"40px","top":"10px","cursor":"pointer","padding":"10px"},attrs:{"medium":"","dark":""},on:{"click":function($event){_vm.showCopyContent = !_vm.showCopyContent}}},[_vm._v(" mdi-content-copy ")]):_vm._e(),_c('v-row',{staticClass:"fill-height ma-0",staticStyle:{"color":"white","font-weight":"bold"},style:(("background: " + _vm.randoGrad + ";  cursor: pointer;")),attrs:{"align":"center","justify":"center"},on:{"click":function($event){_vm.play = true}}},[_vm._v(" Click to view GIF ")])]},proxy:true}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.src),expression:"!src"}],style:(("height: 200px; width: 100%; background: " + _vm.randoGrad))},[_c('v-icon',{staticStyle:{"position":"absolute","right":"10px","top":"10px","cursor":"pointer"},attrs:{"medium":"","dark":""},on:{"click":function($event){_vm.favorite = !_vm.favorite}}},[_vm._v(" "+_vm._s(_vm.favorite ? "mdi-heart" : "mdi-heart-outline")+" ")]),(_vm.newCheck)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"position":"absolute","right":"70px","top":"10px","cursor":"pointer","padding":"10px"},attrs:{"medium":"","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-death-star-variant ")])]}}],null,false,2484455891)},[_c('span',[_vm._v("New!")])]):_vm._e(),(!_vm.isMobile.any())?_c('v-icon',{staticStyle:{"position":"absolute","right":"40px","top":"10px","cursor":"pointer","padding":"10px"},attrs:{"medium":"","dark":""},on:{"click":function($event){_vm.showCopyContent = !_vm.showCopyContent}}},[_vm._v(" mdi-content-copy ")]):_vm._e(),_c('v-row',{staticClass:"fill-height ma-0",staticStyle:{"color":"#ffffff","font-weight":"bold"},attrs:{"align":"center","justify":"center"}},[_vm._v(" No GIF or Image Found ")])],1)],1),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('v-card-subtitle',{staticStyle:{"font-weight":"bold"},attrs:{"color":"orange lighten-2"}},[_vm._v(" "+_vm._s(_vm.bits)+" Bits ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.description)+" ")]),(!_vm.isMobile.any())?_c('selector',{attrs:{"overlay":_vm.showCopyContent,"bits":_vm.bits},on:{"back":function($event){_vm.showCopyContent = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }