<template>
  <v-card class="mx-auto" max-width="344" style="overflow: auto">
    <div style="height: 200px !important; overflow: hidden">
      <v-img
        v-if="src && play"
        height="200px"
        :id="refid"
        @click="play = false"
        :src="`https://firebasestorage.googleapis.com/v0/b/bityalerts.appspot.com/o/${src}?alt=media`"
      >
        <v-icon
          medium
          dark
          style="
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            padding: 10px;
          "
          @click="favorite = !favorite"
        >
          {{ favorite ? "mdi-heart" : "mdi-heart-outline" }}
        </v-icon>
        <v-tooltip bottom v-if="newCheck">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              medium
              dark
              v-bind="attrs"
              v-on="on"
              style="
                position: absolute;
                right: 70px;
                top: 10px;
                cursor: pointer;
                padding: 10px;
              "
            >
              mdi-death-star-variant
            </v-icon>
          </template>
          <span>New!</span>
        </v-tooltip>
        <v-icon
          medium
          dark
          style="
            position: absolute;
            right: 40px;
            top: 10px;
            cursor: pointer;
            padding: 10px;
          "
          v-if="!isMobile.any()"
          @click="showCopyContent = !showCopyContent"
        >
          mdi-content-copy
        </v-icon>
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
            :style="`background: ${randoGrad}`"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-img
        v-show="src && !play"
        height="200px"
        :id="refid"
        class="freezeframe"
        src=""
      >
        <template v-slot:default>
          <v-icon
            medium
            dark
            style="
              position: absolute;
              right: 10px;
              top: 10px;
              cursor: pointer;
              padding: 10px;
            "
            @click="favorite = !favorite"
          >
            {{ favorite ? "mdi-heart" : "mdi-heart-outline" }}
          </v-icon>

          <v-tooltip bottom v-if="newCheck">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                dark
                v-bind="attrs"
                v-on="on"
                style="
                  position: absolute;
                  right: 70px;
                  top: 10px;
                  cursor: pointer;
                  padding: 10px;
                "
              >
                mdi-death-star-variant
              </v-icon>
            </template>
            <span>New!</span>
          </v-tooltip>

          <v-icon
            medium
            dark
            style="
              position: absolute;
              right: 40px;
              top: 10px;
              cursor: pointer;
              padding: 10px;
            "
            v-if="!isMobile.any()"
            @click="showCopyContent = !showCopyContent"
          >
            mdi-content-copy
          </v-icon>

          <v-row
            @click="play = true"
            class="fill-height ma-0"
            align="center"
            justify="center"
            style="color: white; font-weight: bold"
            :style="`background: ${randoGrad};  cursor: pointer;`"
          >
            Click to view GIF
          </v-row>
        </template>
      </v-img>
      <div
        v-show="!src"
        :style="`height: 200px; width: 100%; background: ${randoGrad}`"
      >
        <v-icon
          medium
          dark
          style="position: absolute; right: 10px; top: 10px; cursor: pointer"
          @click="favorite = !favorite"
        >
          {{ favorite ? "mdi-heart" : "mdi-heart-outline" }}
        </v-icon>

        <v-tooltip bottom v-if="newCheck">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              medium
              dark
              v-bind="attrs"
              v-on="on"
              style="
                position: absolute;
                right: 70px;
                top: 10px;
                cursor: pointer;
                padding: 10px;
              "
            >
              mdi-death-star-variant
            </v-icon>
          </template>
          <span>New!</span>
        </v-tooltip>

        <v-icon
          medium
          dark
          style="
            position: absolute;
            right: 40px;
            top: 10px;
            cursor: pointer;
            padding: 10px;
          "
          v-if="!isMobile.any()"
          @click="showCopyContent = !showCopyContent"
        >
          mdi-content-copy
        </v-icon>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
          style="color: #ffffff; font-weight: bold"
        >
          No GIF or Image Found
        </v-row>
      </div>
    </div>

    <v-card-title>
      {{ name }}
    </v-card-title>

    <v-card-subtitle style="font-weight: bold" color="orange lighten-2">
      {{ bits }} Bits
    </v-card-subtitle>

    <v-card-text>
      {{ description }}
    </v-card-text>
    <selector
      v-if="!isMobile.any()"
      :overlay="showCopyContent"
      @back="showCopyContent = false"
      :bits="bits"
    ></selector>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import selector from "./BitySelector";
import moment from "moment";

export default {
  props: {
    src: { type: String, required: false },
    description: { type: String, required: false, default: "" },
    name: { type: String, required: true },
    bits: { type: Number, required: true },
    data: { type: Object, require: true },
  },
  data: () => ({
    logo: null,
    play: false,
    favorite: false,
    showCopyContent: false,
  }),
  components: {
    selector,
  },
  mounted() {
    let fav = this.getFav({
      src: this.src,
      description: this.description,
      name: this.name,
      bites: this.bits,
    });

    if (fav && fav.name) {
      this.favorite = true;
    } else {
      this.favorite = false;
    }
  },
  watch: {
    showCopyContent(data) {
      if (data) {
        window.dataLayer.push({
          'event': 'TileClicked',
          'Event': 'TileClicked',
          'tile_id': this.data.id,
          'event_category' : 'click',
          'event_label' : 'tileclick',
          'value': this.data.id
        });
      }
    },
    favorite(data) {
      if (data) {
        this.setFavAlert({
          src: this.src,
          description: this.description,
          name: this.name,
          bits: this.bits,
        });
      } else {
        this.removeFavAlert({
          src: this.src,
          description: this.description,
          name: this.name,
          bits: this.bits,
        });
      }
    },
  },
  methods: {
    ...mapActions(["setFavAlert", "removeFavAlert"]),
    toggleGif(id) {
      var image = document.getElementById(id);
      if (image.classList && image) {
        if (this.playstate == "pause") {
          image.classList.add("pause");
          this.playstate = "play";
        } else {
          image.classList.remove("pause");
          this.playstate = "pause";
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getFav"]),
    refid() {
      return "tile-" + this.src;
    },
    isMobile() {
      let checks = {
        Android: function () {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
          return (
            navigator.userAgent.match(/IEMobile/i) ||
            navigator.userAgent.match(/WPDesktop/i)
          );
        },
      };

      return {
        ...checks,
        any: function () {
          return (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
            navigator.userAgent.match(/Opera Mini/i) ||
            navigator.userAgent.match(/IEMobile/i) ||
            navigator.userAgent.match(/WPDesktop/i)
          );
        },
      };
    },
    newCheck() {
      if (!this.data.ts) return false;

      let currentDate = moment();
      let ts = moment(this.data.ts);
      return currentDate.diff(ts, "days") < 3;
    },
    randoGrad() {
      var hexValues = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
      ];

      function populate(a) {
        for (var i = 0; i < 6; i++) {
          var x = Math.round(Math.random() * 14);
          var y = hexValues[x];
          a += y;
        }
        return a;
      }

      var newColor1 = populate("#");
      var newColor2 = populate("#");
      var angle = Math.round(Math.random() * 360);

      return (
        "linear-gradient(" +
        angle +
        "deg, " +
        newColor1 +
        ", " +
        newColor2 +
        ")"
      );
    },
  },
};
</script>