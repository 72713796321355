import firebase from 'firebase/app'
import 'firebase/firestore'
export default {
    install (Vue, options = {}) {
        if (!options.config) {
            console.error('[Firebase Plugin] Missing config option')
        }
        if (!options.store) {
            console.error('[Firebase Plugin] Missing store option')
        }

        firebase.initializeApp(options.config);
        
        // Setup instance access
        Vue.prototype.$firebase = firebase.firestore()

        console.log('[Firebase Plugin] Installed!')
    }
}