import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
// import VueGtag from "vue-gtag";
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueFreezeframe from 'vue-freezeframe';
import firebase from './plugins/firebase.js'
Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'
if (isProd) {
  // Disable Logs in prod
  console.log = function () { }
  console.warn = function () { }
}

Vue.use(firebase, {
  store: store,
  config: {
    apiKey: "AIzaSyCbCYGe7e6XOz_C_Bjn84yrCxrqVkrhFVk",
    authDomain: "bityalerts.firebaseapp.com",
    projectId: "bityalerts",
    storageBucket: "bityalerts.appspot.com",
    messagingSenderId: "368223155831",
    appId: "1:368223155831:web:090fcbad6c2dcd9c107872",
    measurementId: "G-QYTB29743Q"
  }
})

Vue.use(VueFreezeframe);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
