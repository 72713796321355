<template>
  <div>
    <v-switch
      v-model="$vuetify.theme.dark"
      hint="This toggles the theme"
      label="Dark Mode"
      color="secondary"
      dark
    ></v-switch>
    <v-switch
      v-model="auto"
      label="Auto Copy on Click"
      color="accent"
      hide-details
      dark
    ></v-switch>
  </div>
</template>
<script>
export default {
  name: "Settings",
  components: {},
  data: () => ({
    get auto() {
      return Boolean(localStorage.getItem("autocopy") == "true" || false);
    },
    set auto(value) {
      localStorage.setItem("autocopy", value);
    },
  }),
  mounted() {
    // this.auto = Boolean(localStorage.getItem("autocopy") || false)
  },
  watch: {
    auto() {
      // localStorage.setItem("autocopy", data);
    },
  },
  computed: {},
};
</script>
