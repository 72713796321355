<template>
  <v-overlay :opacity="1" :value="overlay">
    <v-card class="mx-auto" light min-width="344" style="overflow: auto">
      <v-card-title>Select a Cheer</v-card-title>
      <div class="container-grid">
        <div v-for="(bit, i) in bity" :key="bit.name + i">
          <v-img
            @click="bit.toggle = true"
            class="gd"
            :src="`https://d3aqoihi2n8ty8.cloudfront.net/actions/${bit.name}/dark/static/100/2.png`"
          >
          </v-img>
          <v-overlay :absolute="true" :opacity="0.95" :value="bit.toggle">
            <copy
              v-show="bit.toggle"
              :content="bit.name + bits"
              :label="bit.name + bits"
              :auto="auto"
              @done="finalCopy(bit)"
            >
            </copy>
            <div class="mx-auto" style="text-align: center; font-size: 12px;">Hot tip: Enable "Auto Copy on Click" in the settings page to speed up this process in the future! </div>
          </v-overlay>
        </div>
      </div>
      <v-btn
        class="ml-4 mb-5 mt-10"
        outlined
        text
        color="accent"
        @click="$emit('back')"
      >
        Close
      </v-btn>
    </v-card>
    <v-alert
      transition="scale-transition"
      class="mt-5"
      :value="alert"
      type="success"
      >Code "{{alertText}}" copied to clipboard!</v-alert
    >
  </v-overlay>
</template>

<script>
import copy from "./copy";
export default {
  name: "BtiySelector",
  components: {
    copy,
  },
  props: {
    overlay: { required: true },
    bits: { type: Number, required: true },
  },
  methods: {
    finalCopy(bit) {
      this.alertText = null;
      bit.toggle = false;
      this.alert = true;
      this.alertText = bit.name + this.bits;
    },
  },
  watch: {
    alert(alert) {
      if (alert) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
  },
  data: () => ({
    get auto() {
      return Boolean(localStorage.getItem("autocopy") == "true" || false);
    },
    set auto(value) {
      localStorage.setItem("autocopy", value);
    },
    alert: false,
    alertText: null,
    bity: [
      {
        name: "cheer",
        toggle: false,
      },
      {
        name: "biblethump",
        toggle: false,
      },
      {
        name: "cheerwhal",
        toggle: false,
      },
      {
        name: "corgo",
        toggle: false,
      },
      {
        name: "uni",
        toggle: false,
      },
      {
        name: "showlove",
        toggle: false,
      },
      {
        name: "party",
        toggle: false,
      },
      {
        name: "seemsgood",
        toggle: false,
      },
      {
        name: "pride",
        toggle: false,
      },
      {
        name: "kappa",
        toggle: false,
      },
      {
        name: "frankerz",
        toggle: false,
      },
      {
        name: "heyguys",
        toggle: false,
      },
      {
        name: "dansgame",
        toggle: false,
      },
      {
        name: "elegiggle",
        toggle: false,
      },
      {
        name: "trihard",
        toggle: false,
      },
      {
        name: "kreygasm",
        toggle: false,
      },
      {
        name: "4head",
        toggle: false,
      },
      {
        name: "swiftrage",
        toggle: false,
      },
      {
        name: "notlikethis",
        toggle: false,
      },
      {
        name: "failfish",
        toggle: false,
      },
      {
        name: "vohiyo",
        toggle: false,
      },
      {
        name: "pjsalt",
        toggle: false,
      },
      {
        name: "mrdestructoid",
        toggle: false,
      },
      {
        name: "bday",
        toggle: false,
      },
      {
        name: "ripcheer",
        toggle: false,
      },
      {
        name: "shamrock",
        toggle: false,
      },
    ],
  }),
  computed: {},
};
</script>
<style scoped>
.container-grid {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 40px);
  grid-gap: 4px;
  /* padding: 30px; */
  /* margin-top: 350px; */
}

.tiles-grid {
  /* width: 60px; */
}

.gd {
  transition: all 0.4s ease-in-out;
  border-radius: 6px;
}

.gd:hover {
  cursor: pointer;
  background-color: #e27396;
  opacity: 0.8;
  transition: all 0.4s ease-in-out;
}
</style>