<template>
  <div class="home">
    <div class="hlogo">
      <v-img
        src="../assets/logo.svg"
        style="
          display: inline-block;
          vertical-align: middle;
          margin-top: -12px;
          margin-right: 10px;
        "
        heigth="40px"
        width="40px"
      />
      <h2
        style="
          color: #6441a5;
          user-select:none;
          -moz-user-select:none;
          -webkit-user-select:none;
          -webkit-touch-callout:none;
          -ms-user-select:none;
          font-size: 30px;
          display: inline-block;
        "
      >
        Bity Alerts
      </h2>
    </div>
    <v-img
       src="../assets/home1.svg"
       class="svgg sec1"
    ></v-img>

    <v-img
       src="../assets/home0.svg"
       class="svgg sec0"
    ></v-img>
    <div class="hwrap mobileonly">
      <div class="hheader">Looking for the perfect bit alert for your favorite streamer?</div>
      <div class="hcontent">Then BityAlerts is for you!</div>
    </div>  

    <div class="grid-container gl">
      <div class="himage">
        <v-img
          src="../assets/home2.svg"
        ></v-img>
      </div>
      <div class="hwrap">
         <div class="hheader">What is BityAlerts?</div>
        <div class="hcontent">A platform built to help streamers and viewers connect via donation based stream alerts.</div>
      </div>  
    </div>

    <div class="grid-container gr">
      <div class="hwrap">
        <div class="hheader">How does BityAlerts Help?</div>
        <div class="hcontent">BityAlerts piggybacks off  of Twitch alerts, which are a form of donation to the streamer. BityAlerts makes rewards for these donations more visible to the viewer!</div>
      </div>  
      <div class="himage">
        <v-img
          src="../assets/home3.svg"
        ></v-img>
      </div>
    </div>

    <div class="grid-container gl">
      <div class="himage">
        <v-img
          src="../assets/home4.svg"
        ></v-img>
      </div>
      <div class="hwrap">
         <div class="hheader">Looking to display your own Alerts Rewards?</div>
        <div class="hcontent">Great! Join us over <a style="color:#E27396 !important;" href="https://app.bityalerts.com/signup" target="_blank">HERE</a>  to get started</div>
      </div>  
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data: () => ({
  }),
  computed: {
  }
}
</script>
<style scoped>
.home {
  margin: -12px !important;
  padding-top: 10px;
  background-color: #818479;
  color: #ffffff;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 50% 1fr;

  width: 70%;
  margin: 0 auto;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

.hlogo {
  margin: 0 auto;
  width: 200px;
  padding-bottom: 20px;
  display: none;
}

.gr {
  grid-template-areas:
  'header image'
  'main image';
}

.gl {
  grid-template-areas:
  'image header'
  'image main';
}

.himage {
  grid-area: image;
}

.hheader {
  padding-top: 22%;
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  grid-area: header;
}

.hcontent {
  font-size: 25px;
  text-align: center;
  grid-area: main;
}

.sec1 {
  margin: 0 auto;
  max-width: 60%;
  width: 60%;
  display: block;
}

.sec0, .mobileonly {
  display: none;
}




@media only screen and (max-width: 1043px) {
  .home {
    margin: 0px !important;
  }

  .sec1 {
    display: none;
  }

  .hlogo {
    display: block;
  }

  .sec0 {
    margin: 0 auto;
    width: 100%;
    display: block;
  }

  .mobileonly {
    display: block;
  }


  .grid-container {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 100% 1fr;

    width: 90%;
    margin: 0 auto;
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .gr {
    grid-template-areas:
    'image image'
    'header header'
    'main main';
  }

  .gl {
    grid-template-areas:
    'image image'
    'header header'
    'main main';
  }

  .hheader {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    grid-area: header;
    padding-top: 20px;
  }

  .hcontent {
    font-size: 20px;
    text-align: center;
    grid-area: main;
  }

}


</style>