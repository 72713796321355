import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import fourohfour from '../views/404.vue'
import Alerts from '../views/Alerts.vue'
import Favorites from '../views/Favorites.vue'
import Settings from '../views/Setting.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/404',
    name: 'NotFound',
    component: fourohfour
  },
  {
    path: '/:twitchid',
    name: 'Alerts',
    component: Alerts
  },
  {
    path: '*',
    redirect: '404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
