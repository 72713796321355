<template>
  <div @click="copyContent" class="copy">
    <v-text-field
      :name="content"
      :label="'Click to copy'"
      type="text"
      v-model="content"
      :readonly="true"
      class="ml-5 mr-5"
      color="white"
      :ref="content"
      outlined
      dense
      append-icon="mdi-content-copy"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    content: { type: String, required: true },
    label: { type: String, required: true },
    auto: { type: Boolean, required: false, default: false }
  },
  mounted () {
    if (this.auto) this.copyContent ()
  },
  methods: {
    copyContent() {
      var copyText = document.getElementsByName(this.content)[0];
      copyText.disabled = false;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      copyText.disabled = true;
      this.$emit('done', this.content)
    },
  },
};
</script>
<style scoped>
.copy {
  cursor: pointer;
}

.copy:hover > div {
  outline: red;
}
</style>