<template>
  <div class="home">
    <v-row
      v-if="loading"
      style="margin-top: 40px;"
      align="center"
      justify="center"
    >
      <!-- <v-progress-circular
        indeterminate
        color="grey lighten-5"
      ></v-progress-circular> -->
      <div style="margin-left: 20px; font-weight: bold;">Hold tight, loading alerts...</div>
    </v-row>

    <v-expansion-panels class="mb-5" v-if="exists">
      <v-expansion-panel :style="`background-color: ${$vuetify.theme.themes[theme].navigationbar} !important;`">
        <v-expansion-panel-header disable-icon-rotate>
          <template v-slot:actions>
            <v-icon color="teal">
              mdi-death-star-variant
            </v-icon>
          </template>
          Click here to see new alerts!
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="container-grid" v-show="exists">
          <tiles
            class="tiles-grid"
            v-for="(alert, i) in newalerts"
            :key="alert.description + i"
            :src="alert.src"
            :bits="alert.bits"
            :name="alert.name"
            :data="alert"
            :description="alert.description">
          </tiles>
        </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="mb-5" v-if="TAlerts && TAlerts.length > 0">
      <v-expansion-panel :style="`background-color: ${$vuetify.theme.themes[theme].navigationbar} !important;`">
        <v-expansion-panel-header disable-icon-rotate>
          <template v-slot:actions>
            <v-icon color="teal">
              mdi-trophy-award
            </v-icon>
          </template>
          Click here to see top alerts!
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="container-grid">
            <tiles
              class="tiles-grid"
              v-for="(alert, i) in TAlerts"
              :key="alert.description + i + 'TALERTS'"
              :src="alert.src"
              :bits="alert.bits"
              :name="alert.name"
              :data="alert"
              :description="alert.description">
            </tiles>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="container-grid">
      <tiles
        class="tiles-grid"
        v-for="(alert, i) in alerts"
        :key="alert.description + i"
        :src="alert.src"
        :bits="alert.bits"
        :name="alert.name"
        :data="alert"
        :description="alert.description">
      </tiles>
    </div>
  </div>
</template>

<script>
import tiles from '../components/Tiles.vue'
import _ from 'lodash'
// import _ from 'lodash'
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'
export default {
  name: 'Alerts',
  components: {
    tiles
  },
  data: () => ({
    twitcher: null,
    exists: false,
    topAlerts: {
      exists: true,
      list: [
        {
          id: 'bzJdWMk3wTNpHwXi1r5z',
          p: 1
        },
        {
          id: '6ywmrJB9IaVp4iJ6oay4',
          p: 2
        },
        {
          id: 'BxqmXIT1j4fXyyBqvqWp',
          p: 3
        },
        {
          id: 'NhZ9DWHnG...Auwuly6g',
          p: 4
        },
        {
          id: 'PexGkU44ZNNCxjjoy6vi',
          p: 5
        },
        {
          id: 'ZlxsdtGbeqUU93IbUpdU',
          p: 6
        },
        {
          id: 'qTYBAMZtks0O6tBb82EN',
          p: 7
        }
      ]
    }
  }),
  mounted () {
    this.newalerts()
  },
  methods: {
    async newalerts () {
      this.newalerts = [];
      let that = this
      const streamers = this.$firebase;
      this.twitcher = this.$route.params.twitchid;
      if (!this.twitcher) return;

      await streamers
      .collection("alerts")
      .where("streamer", "==", this.twitcher.toLowerCase())
      .where('ts', '>', moment().tz("America/Los_Angeles").subtract(7,'d').toISOString())
      .get()
      .then((querySnapshot) => {
        this.exists = querySnapshot.docs.length > 0;
        querySnapshot.docs.forEach((element) => {
          that.newalerts.push({
            id: element.id,
            ...element.data(),
          });
        });
      });
    }
  },
  computed: {
    ...mapGetters([
      'alerts', 'loading'
    ]),
    TAlerts() {
      let filtered = _.filter(this.alerts, (alert) => {
        return _.find(this.topAlerts.list, (listItem) => {
          alert.p = listItem.p
          return listItem.id == alert.id
        })
      })
      
      return _.sortBy(filtered, (i) => {
        return i.p
      })
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  }
}
</script>
<style scoped>
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 10px;
}

.tiles-grid {
  width: 300px
}

</style>